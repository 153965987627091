import Image from 'next/image';
import { data } from './_data';
import dynamic from 'next/dynamic';
import { LazLoader } from '../box-loader/LazLoader';

const Marquee = dynamic(() => import(`react-fast-marquee`), {
  ssr: true,
  loading: () => <LazLoader />,
});

export const BrandCollabs = () => {
  return (
    <>
      <>
        <div className={[``, `font-suisseRegular`].join(` `)}>
          <h2
            className={`text-white pb-7 px-2 font-black text-2xl md:text-5xl  text-center mt-20 md:mt-40 mb-2`}
          >
            Our Brand Collabs
          </h2>
          <div
            className={`bg-[#282234] py-5 flex items-center justify-center flex-wrap`}
          >
            <Marquee autoFill>
              {data.map((data) => (
                <div key={data.id} className="">
                  <img
                    className="brand-image px-9 max-h-24 w-auto h-56"
                    src={data.img.src}
                    alt="Brand Image"
                    onError={(ev) =>
                      (ev.currentTarget.src = `https://picsum.photos/500`)
                    }
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
        <style>{`
        .brand-image {
          
          
          margin: 2.5rem 0;
          display: block;
          object-fit: contain; 
        }
        @media only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-resolution: 192dpi) {
          .brand-image {
            image-rendering: -webkit-optimize-contrast;
          }
        }
        @supports (object-fit: cover) {
          .brand-image {
            object-fit: cover;
          }
        }
      `}</style>
      </>
    </>
  );
};
